body {
  overscroll-behavior-y: contain;
  min-width: 320px;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Betm Rounded";
}

#root {
  white-space: pre-line;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App.loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "Betm Rounded";
  src: url("../fonts/Typesketchbook - BetmRounded-Regular.otf") format("opentype"), url("../fonts/Typesketchbook - BetmRounded-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Betm Rounded Medium";
  src: url("../fonts/Typesketchbook - BetmRounded-Medium.otf") format("opentype"), url("../fonts/Typesketchbook - BetmRounded-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "Betm Rounded Semilight";
  src: url("../fonts/Typesketchbook - BetmRounded-SemiLight.otf") format("opentype");
}
header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 25px;
  height: 100px;
  box-sizing: border-box;
  max-width: 1393px;
  margin: 0 auto;
}
header .storeCodeSupporterWrapper {
  margin-left: auto;
  display: flex;
  align-items: center;
}
header .storeCodeSupporterWrapper.showBorder {
  border-left: 1px solid #E9E3D6;
  padding-left: 10px;
}
header .storeCodeSupporterWrapper .storeCodeSupporterText {
  font: 20px/23px Betm Rounded Medium;
  color: #1E285A;
  margin-right: 10px;
  white-space: nowrap;
}
header .storeCodeSupporterWrapper .storeCodeSupporter {
  background-color: #fff;
  height: 60px;
  width: 60px;
}
@media (max-width: 768px) {
  header .storeCodeSupporterWrapper .storeCodeSupporter {
    height: 40px;
    width: 40px;
  }
}
header.account {
  padding: 0 85px 0 39px;
  max-width: initial;
  margin: 0;
}
header .account-burger-wrap {
  display: none;
}
header .back-wrap {
  display: none;
}
header .desktop-dropdown {
  padding-left: 30px;
  border-left: 2px solid #E9E3D6;
}
header .desktop-dropdown button {
  border: none;
  background: none;
  outline: none;
  display: flex;
  align-items: center;
}
header .desktop-dropdown button img {
  width: 35px;
  height: 35px;
}
header .account-title {
  display: none;
  color: #fff;
  font: 21px Betm Rounded Medium;
  width: 100%;
  padding-left: 20px;
}
header .account-title.center {
  text-align: center;
}
header .stepper-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px 0 40px;
}
header .stepper-wrapper .stepper-header {
  font: 25px/29px Betm Rounded Medium;
  color: #1E285A;
  display: flex;
  align-items: center;
}
header .stepper-wrapper .stepper .steps-mobile {
  display: none;
}
header .login-wrap {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
header .login-wrap nav {
  display: flex;
  align-items: center;
  margin-right: 22px;
}
header .login-wrap nav a {
  font: 20px/26px Betm Rounded Medium;
  color: #1E285A;
  margin-left: 18px;
  cursor: pointer;
  text-decoration: none;
}
header .login {
  padding: 0 32px;
  border-left: 2px solid #E9E3D6;
  height: 62px;
  font: 20px/23px Betm Rounded Medium;
  display: flex;
  align-items: center;
  width: 262px;
}
header .login img {
  margin-left: 15px;
}
header .mobile-logo {
  display: none;
}
@media (max-width: 1200px) {
  header.account {
    padding: 0 11px 0 20px;
    height: 50px;
  }
  header.account .logo-link {
    display: none !important;
  }
  header .account-title {
    display: block;
  }
  header .login-wrap {
    display: none;
  }
  header .login-wrap nav {
    display: none;
  }
  header .desktop-dropdown {
    display: none !important;
  }
  header .mobile-logo {
    margin-top: 5px;
    display: block;
    width: 68px;
    height: 18px;
  }
  header.account {
    background: #1E285A;
  }
  header .back-wrap {
    display: flex;
    color: #fff;
    font-size: 45px;
  }
  header .account-burger-wrap {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 60px;
    width: 60px;
  }
  header .account-burger-wrap .bm-overlay {
    background: #fff !important;
  }
  header .account-burger-wrap .bm-burger-button {
    padding: 13px 18px;
  }
  header .account-burger-wrap .bm-burger-button svg {
    color: #fff;
  }
  header .account-burger-wrap .bm-cross-button {
    height: 48px !important;
    width: 48px !important;
    left: 8px !important;
    border-radius: 50%;
    background-color: #fff;
  }
  header .account-burger-wrap .bm-menu-wrap {
    width: 100% !important;
  }
  header .account-burger-wrap .bm-menu-wrap .bm-menu .bm-item.clement {
    min-width: 113px;
    max-width: 250px;
    height: 36px;
    border: 2px solid #E9E3D6;
    border-radius: 18px;
    font: 18px/24px Betm Rounded;
    color: #1E285A;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 13px 0 5px;
    background: transparent;
  }
  header .account-burger-wrap .bm-menu-wrap .bm-menu .bm-item.clement img {
    height: 26px;
    margin-right: 5px;
  }
  header .account-burger-wrap .bm-menu-wrap .bm-menu .bm-item.clement span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 90%;
    text-align: center;
  }
  header .account-burger-wrap .bm-menu-wrap .bm-menu nav {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  header .account-burger-wrap .bm-menu-wrap .bm-menu nav a, header .account-burger-wrap .bm-menu-wrap .bm-menu nav button, header .account-burger-wrap .bm-menu-wrap .bm-menu nav > span {
    text-decoration: none;
    font: 21px/23px Betm Rounded Medium;
    color: #1E285A;
    margin-top: 25px;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
  }
  header .account-burger-wrap .bm-menu-wrap .bm-menu nav a:hover, header .account-burger-wrap .bm-menu-wrap .bm-menu nav button:hover, header .account-burger-wrap .bm-menu-wrap .bm-menu nav > span:hover {
    opacity: 0.8;
  }
  header .account-burger-wrap .bm-menu-wrap .bm-menu nav .menu-divider {
    border-top: 2px solid #E9E3D6;
    width: 75%;
    margin: 25px 0 30px;
  }
  header .login {
    padding: 0 0 0 11px;
    height: unset;
  }
  header .login span {
    display: none;
  }
  header .login img {
    margin: 0;
  }
}
@media (max-width: 768px) {
  header {
    padding: 0 11px 0 20px;
    height: 50px;
  }
  header .stepper-wrapper {
    padding: 0 20px;
  }
  header .stepper-wrapper .stepper-header {
    font: 13pt Betm Rounded;
  }
  header .stepper-wrapper .stepper-header span {
    display: none;
  }
  header .stepper-wrapper .stepper {
    display: flex;
    align-items: center;
  }
  header .stepper-wrapper .stepper .steps-mobile {
    display: block;
    margin: 5px 10px 0 0;
    color: #F48040;
    font: 13pt Betm Rounded Medium;
  }
}

.price-details-wrap {
  display: flex;
  align-items: center;
}
.price-details-wrap button {
  background: none;
  border: none;
  display: flex;
  align-items: center;
}
.price-details-wrap button .price {
  display: flex;
  align-items: center;
  font: 39px/16px Betm Rounded Medium;
  color: #1E285A;
}
.price-details-wrap button .price span {
  font: 14px/16px Betm Rounded;
  white-space: nowrap;
  min-width: max-content;
}
.price-details-wrap button .icon.mobile {
  display: none;
}
@media (max-width: 768px) {
  .price-details-wrap button .price, .price-details-wrap button .icon {
    display: none;
  }
  .price-details-wrap button .icon.mobile {
    display: block;
  }
}

.price-details {
  display: flex;
  flex-direction: column;
}
.price-details h4 {
  font: 20px/25px Betm Rounded Medium;
  color: #F48040;
  display: flex;
  justify-content: space-between;
}
.price-details h4 .header-price {
  font: 16px/14px Betm Rounded;
  color: #7A7A7A;
  display: flex;
  align-items: center;
}
.price-details h4 .subheader {
  display: flex;
  flex-direction: column;
  font: 14px/14px Betm Rounded;
  color: #1E285A;
}
.price-details h4 .subheader span {
  font: 20px/25px Betm Rounded Medium;
  color: #F48040;
}
.price-details .scooter-setup {
  border-bottom: 1px solid #E9E3D6;
  padding-bottom: 8px;
  margin-bottom: 8px;
  font: 16px/18px Betm Rounded Medium;
}
.price-details .divider {
  width: 100%;
  border-top: 1px solid #E9E3D6;
  margin: 20px 0;
}
.price-details ul li {
  display: flex;
  justify-content: space-between;
  font: 16px/22px Betm Rounded;
}
.price-details ul li .title {
  color: #1E285A;
  max-width: 225px;
  font: 16px/18px Betm Rounded Medium;
}
.price-details ul li .content {
  color: #7A7A7A;
}
.price-details ul li .content .main-price {
  display: flex;
  justify-content: flex-end;
}
.price-details ul li .content .main-price span {
  font-weight: bold;
}
.price-details ul li .content .sub-price .price {
  text-align: right;
}
.price-details ul li .content .price {
  font: 16px/14px Betm Rounded;
}
.price-details ul li .content .price.crossed {
  text-decoration: line-through;
}
.price-details ul li .content .period {
  font: 10px/14px Betm Rounded;
}
.price-details p {
  background: #1E285A;
  border-radius: 10px;
  font: 16px/17px Betm Rounded;
  color: #FFFFFF;
  padding: 16px 14px;
  margin: 27px 0 10px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  min-height: 74px;
  justify-content: flex-end;
  box-sizing: border-box;
  flex-wrap: wrap;
  background: #fff;
  width: 100%;
  z-index: 2;
  border-top: 2px solid #E9E3D6;
}
.footer button {
  outline: none;
  cursor: pointer;
  border: none;
}
.footer button:disabled, .footer button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.footer .prev-button-wrap {
  margin-left: 30px;
  position: absolute;
  bottom: 14px;
  left: 10px;
}
.footer .prev-button-wrap button {
  width: 48px;
  height: 48px;
  background: transparent url("../images/icons/arrow.svg") 0% 0% no-repeat padding-box;
}
.footer .notification {
  max-width: 460px;
  font: 20px/23px Betm Rounded;
  color: #B50F0F;
  flex: 3;
}
.footer .notification.mobile {
  display: none;
  margin: 0;
}
.footer .next-button-wrap {
  display: flex;
  margin-right: 123px;
}
.footer .next-button-wrap .skip, .footer .next-button-wrap .skip-mobile {
  text-decoration: underline;
  font: 20px/23px Betm Rounded;
  color: #1E285A;
  background: none;
}
.footer .next-button-wrap .skip-mobile {
  font-weight: bold;
  display: none;
}
.footer .next-button-wrap .next-wrap {
  position: relative;
}
.footer .next-button-wrap .next-wrap > div {
  position: absolute;
  left: 40px;
  top: 12px;
}
.footer .next-button-wrap .next {
  position: relative;
  width: 200px;
  height: 48px;
  background: transparent linear-gradient(257deg, #F48040 0%, #EF4746 100%) 0% 0% no-repeat padding-box;
  border-radius: 24px;
  font: 20px/23px Betm Rounded;
  font-weight: bold;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .footer {
    padding: 11px 0 7px;
  }
  .footer .notification.mobile {
    display: flex;
    width: 100%;
    font: 12px/15px Betm Rounded;
    max-width: 90%;
    flex: auto;
    margin: 11px auto 8px;
    text-align: center;
  }
  .footer .notification.desktop {
    display: none;
  }
  .footer .next-button-wrap {
    margin-left: 63px;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: center;
    margin-right: 0;
  }
  .footer .next-button-wrap .next-wrap > div {
    left: 0;
  }
  .footer .next-button-wrap .skip-mobile {
    display: block;
  }
  .footer .next-button-wrap .skip {
    display: none;
  }
  .footer .next-button-wrap .next {
    margin-left: -48px;
  }
  .footer .next-button-wrap.skipActive .next {
    margin: 0;
  }
  .footer .prev-button-wrap {
    margin-left: 0;
  }
}

button {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently                         not supported by any browser */
}

.notification-wrap {
  box-sizing: border-box;
  background: #E9E3D6 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 15px 24px;
  margin: 10px auto 0;
  font: 20px/23px Betm Rounded;
  color: #1E285A;
}
.notification-wrap .for-info {
  color: #F48040;
}
.notification-wrap span .link {
  text-decoration: underline;
  cursor: pointer;
  color: #1E285A;
}
.notification-wrap span .link:hover, .notification-wrap span .link:active, .notification-wrap span .link:visited {
  color: #1E285A;
}
@media (max-width: 768px) {
  .notification-wrap {
    margin: 10px auto 0;
    font: 17px/20px Betm Rounded;
  }
}

.common-wrap {
  background: #1E285A;
  min-height: 100vh;
  padding-top: 50px;
}
.common-wrap .content-with-image-wrap {
  display: flex;
  min-height: 100vh;
}
.common-wrap .content-with-image-wrap .scooter-image {
  width: 50%;
}
.common-wrap .content-with-image-wrap .scooter-image img, .common-wrap .content-with-image-wrap .scooter-image picture {
  width: 100%;
}
.common-wrap .content-with-image-wrap .image-content {
  border-radius: 10px 10px 0 0;
  width: 644px;
  min-height: 80vh;
  margin: 0 30px;
  background: #fff;
  box-sizing: border-box;
  padding: 50px 82px 63px;
}
.common-wrap .content-with-image-wrap .image-content .header {
  color: #1E285A;
  text-align: left;
  font: 39px/45px Betm Rounded Medium;
}
.common-wrap .content-with-image-wrap .image-content .divider {
  width: 80px;
  height: 8px;
  background: #F48040 0 0 no-repeat padding-box;
  border-radius: 10px;
  margin: 10px 0 0;
}
.common-wrap .content-with-image-wrap .image-content .scooter-info {
  border-top: 1px solid #CFCFCF;
  border-bottom: 1px solid #CFCFCF;
  padding: 22px 0 17px;
  margin-top: 40px;
}
.common-wrap .content-with-image-wrap .image-content .scooter-info h4 {
  font: 20px/23px Betm Rounded Medium;
  color: #1E285A;
}
.common-wrap .content-with-image-wrap .image-content .scooter-info p {
  font: 20px/23px Betm Rounded;
  color: #1E285A;
  font-weight: lighter;
}
.common-wrap .content-with-image-wrap .image-content .user-types-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 817px;
  margin: 40px auto 0;
}
.common-wrap .content-with-image-wrap .image-content .user-types-wrap .user-type {
  width: 100%;
  min-height: 74px;
  padding: 0 30px;
  font: 25px/29px Betm Rounded Medium;
  margin: 10px 0;
  flex-direction: row;
  justify-content: space-between;
}
.common-wrap .content-with-image-wrap .image-content .user-types-wrap .user-type .description {
  font: 20px/23px Betm Rounded;
}
.common-wrap .content-with-image-wrap .image-content .user-types-wrap .user-type .color {
  width: 63px;
  height: 63px;
}
.common-wrap .content-with-image-wrap .image-content .user-types-wrap .user-type .color img {
  height: 100%;
  width: 100%;
}
.common-wrap .content-with-image-wrap .image-content .user-types-wrap .user-type.active {
  background: #E9E3D600 0% 0% no-repeat padding-box;
  color: #1E285A;
  border: 3px solid #F48040;
}
.common-wrap .content-with-image-wrap .image-content .delivery-wrap {
  color: #1E285A;
  padding: 21px 0;
  margin: 50px 0 0;
  border-top: 1px solid #CFCFCF;
}
.common-wrap .content-with-image-wrap .image-content .delivery-wrap h3 {
  font: 20px/26px Betm Rounded Medium;
}
.common-wrap .content-with-image-wrap .image-content .delivery-wrap p {
  font: 20px/26px Betm Rounded;
  font-weight: lighter;
}
.common-wrap .content-with-image-wrap .image-content .user-types-wrap.scooter-power-wrap .user-type.scooter-type {
  font: 39px/45px Betm Rounded Medium;
  flex-direction: column;
}
.common-wrap .content-with-image-wrap .image-content .user-types-wrap.scooter-power-wrap .user-type.scooter-type span {
  font: 25px/45px Betm Rounded Medium;
}
.common-wrap .content {
  border-radius: 10px 10px 0px 0px;
  min-height: 100vh;
  max-width: 1393px;
  margin: 0 auto;
  background: #fff;
  box-sizing: border-box;
  position: relative;
  padding: 50px 101px 74px;
}
.common-wrap .content .attentionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  color: #1E285A;
}
.common-wrap .content .attentionWrapper .attentionTitle {
  font-size: 28px;
  text-align: center;
  font-family: Betm Rounded;
  font-weight: 600;
}
.common-wrap .content .attentionWrapper .attentionSubtitle {
  text-align: center;
  font-size: 20px;
  font-family: Betm Rounded;
}
.common-wrap .content .attentionWrapper .attentionLink {
  background: transparent linear-gradient(257deg, #f48040, #ef4746) 0 0 no-repeat padding-box;
  padding: 6px 24px;
  border-radius: 24px;
  text-decoration: none;
  font: 20px/23px Betm Rounded;
  font-weight: 700;
  color: #fff;
}
.common-wrap .content .header {
  color: #1E285A;
  text-align: center;
  font: 39px/45px Betm Rounded Medium;
}
.common-wrap .content .subheader {
  font: 30px/40px Betm Rounded;
  color: #1E285A;
  text-align: center;
}
.common-wrap .content .several-scooters {
  font: 17px/20px Betm Rounded;
  color: #1E285A;
  text-align: center;
  margin-top: 20px;
}
.common-wrap .content .several-scooters a {
  text-decoration: underline;
  cursor: pointer;
  color: #1E285A;
}
.common-wrap .content .divider {
  width: 80px;
  height: 8px;
  background: #F48040;
  border-radius: 10px;
  margin: 10px auto 0;
}
.common-wrap .content .user-types-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 817px;
  margin: 70px auto 20px;
}
.common-wrap .content .user-types-wrap .user-type {
  width: 257px;
  height: 106px;
  font: 25px/29px Betm Rounded Medium;
  padding: 8px;
}
.common-wrap .content .user-types-wrap .user-type .type-description {
  font: 16px/16px Betm Rounded;
  font-weight: lighter;
}
.common-wrap .content .user-types-wrap.scooter-power-wrap .user-type.scooter-type {
  font: 39px/45px Betm Rounded Medium;
  flex-direction: column;
}
.common-wrap .content .user-types-wrap.scooter-power-wrap .user-type.scooter-type span {
  font: 25px/45px Betm Rounded Medium;
}
@media (max-width: 1024px) {
  .common-wrap .content .user-types-wrap {
    flex-direction: column;
    align-items: center;
  }
  .common-wrap .content .user-types-wrap .user-type {
    width: 95%;
    margin: 10px 0;
  }
}
@media (max-width: 768px) {
  .common-wrap {
    padding: 12px 0 0;
    height: unset;
  }
  .common-wrap .content {
    padding: 15px 10px 80px;
    margin: 0 10px;
  }
  .common-wrap .content .header {
    font: 21px/24px Betm Rounded Medium;
  }
  .common-wrap .content .divider {
    width: 40px;
    height: 4px;
    margin: 10px auto 15px;
  }
  .common-wrap .content .user-types-wrap {
    margin: 0 auto 20px auto;
    margin: 0 auto 20px;
  }
  .common-wrap .content .user-types-wrap .user-type {
    width: 95%;
    height: 74px;
    margin: 3px auto;
    font: 21px/24px Betm Rounded Medium;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
  }
  .common-wrap .content .subheader {
    font: 17px/19px Betm Rounded Regular;
  }
  .common-wrap .content .several-scooters {
    font: 17px/20px Betm Rounded;
  }
  .common-wrap .content .user-types-wrap.scooter-power-wrap .user-type.scooter-type {
    height: 74px;
    box-sizing: border-box;
    font: 33px/26px Betm Rounded Medium;
  }
  .common-wrap .content .user-types-wrap.scooter-power-wrap .user-type.scooter-type span {
    font: 21px/26px Betm Rounded Medium;
  }
  .common-wrap .content-with-image-wrap {
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 10px;
  }
  .common-wrap .content-with-image-wrap .scooter-image {
    width: 100%;
  }
  .common-wrap .content-with-image-wrap .image-content {
    padding: 15px 10px;
    margin: 0;
    width: 100%;
  }
  .common-wrap .content-with-image-wrap .image-content .header {
    text-align: center;
    font: 21px/24px Betm Rounded Medium;
  }
  .common-wrap .content-with-image-wrap .image-content .divider {
    margin: 10px auto 0;
  }
  .common-wrap .content-with-image-wrap .image-content .user-types-wrap {
    justify-content: space-around;
  }
  .common-wrap .content-with-image-wrap .image-content .user-types-wrap .user-type {
    padding: 5px 15px;
    max-width: 161px;
    width: 45%;
    justify-content: center;
  }
  .common-wrap .content-with-image-wrap .image-content .user-types-wrap .user-type .description, .common-wrap .content-with-image-wrap .image-content .user-types-wrap .user-type .model {
    display: none;
  }
  .common-wrap .content-with-image-wrap .image-content .delivery-wrap {
    display: flex;
    margin-top: 5px;
  }
  .common-wrap .content-with-image-wrap .image-content .delivery-wrap h3, .common-wrap .content-with-image-wrap .image-content .delivery-wrap p {
    width: 40%;
    text-align: center;
  }
}

.subscribtions-details-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 1393px;
  background: #ffffff 0 0 no-repeat padding-box;
  border-radius: 10px 10px 0 0;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 47px 60px 100px;
}
.subscribtions-details-wrap .scooter-info {
  font: 20px/23px Betm Rounded;
  font-weight: lighter;
  color: #1e285a;
  border-top: 1px solid #cfcfcf;
  border-bottom: 1px solid #cfcfcf;
  width: 100%;
  margin-bottom: 45px;
  padding: 20px 0;
}
.subscribtions-details-wrap .scooter-info h6 {
  font: 20px/23px Betm Rounded Medium;
}
.subscribtions-details-wrap .scooter-info .semilight {
  font: 20px/23px Betm Rounded Semilight;
}
.subscribtions-details-wrap .scooter-title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-family: Betm Rounded;
  color: #1e285a;
}
.subscribtions-details-wrap .scooter-title h2 {
  display: block;
  font-size: 33px;
  margin: 0;
}
.subscribtions-details-wrap .scooter-title h2 span {
  font-size: 17px;
  font-weight: 400;
}
.subscribtions-details-wrap .scooter-title .bonus-eco-text {
  font-size: 17px;
  font-weight: 400;
}
.subscribtions-details-wrap > h2,
.subscribtions-details-wrap > div h2 {
  font: 33px/38px Betm Rounded Medium;
  color: #1e285a;
  margin: 50px 0 0;
  display: flex;
  align-items: center;
}
.subscribtions-details-wrap > h2 .required,
.subscribtions-details-wrap > div h2 .required {
  font-size: 33px;
  color: #f48040;
}
.subscribtions-details-wrap > h2 .bonus-wrap,
.subscribtions-details-wrap > div h2 .bonus-wrap {
  font: 17px/43px Betm Rounded Medium;
  color: #1e285a;
}
.subscribtions-details-wrap .divider {
  width: 80px;
  height: 8px;
  background: #f48040 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin: 10px 0 40px;
}
.subscribtions-details-wrap .lists-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.subscribtions-details-wrap .inclusives-wrap {
  word-break: break-word;
  padding: 40px 20px 40px 40px;
  background: #1e285a;
  border-radius: 10px;
}
.subscribtions-details-wrap .inclusives-wrap h5 {
  font: 25px/29px Betm Rounded Medium;
  color: #f48040;
  text-transform: uppercase;
  margin-bottom: 18px;
}
.subscribtions-details-wrap .inclusives-wrap ul {
  font: 20px/21px Betm Rounded Medium;
  color: #ffffff;
  text-transform: uppercase;
}
.subscribtions-details-wrap .inclusives-wrap ul li {
  margin-bottom: 10px;
  display: flex;
  align-items: baseline;
}
.subscribtions-details-wrap .inclusives-wrap ul li::before {
  content: "";
  display: inline-block;
  min-width: 10px;
  min-height: 10px;
  max-width: 10px;
  max-height: 10px;
  margin-right: 10px;
  background-color: #f48040;
  border-radius: 50%;
}
.subscribtions-details-wrap .inclusives-wrap ul li img {
  vertical-align: middle;
  margin-left: 10px;
  margin-top: 4px;
  align-self: flex-start;
}
.subscribtions-details-wrap .offer-wrap {
  padding: 40px 25px 40px 25px;
  background: #f48040;
  border-radius: 10px;
  min-width: 368px;
}
.subscribtions-details-wrap .offer-wrap h5 {
  font: 25px/29px Betm Rounded Medium;
  color: #1e285a;
  text-transform: uppercase;
  margin-bottom: 28px;
}
.subscribtions-details-wrap .offer-wrap ul {
  font: 20px/21px Betm Rounded Medium;
  color: #ffffff;
  text-transform: uppercase;
}
.subscribtions-details-wrap .offer-wrap ul li {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.subscribtions-details-wrap .offer-wrap ul li::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 2px 10px 0 0;
  background-color: #1e285a;
  border-radius: 50%;
}
.subscribtions-details-wrap .offer-wrap ul li .offer {
  flex: 1;
}
.subscribtions-details-wrap .offer-wrap .introductory-offer-container {
  border-top: 1px solid #fff;
  padding: 17px 0 0;
}
.subscribtions-details-wrap .offer-wrap .introductory-offer-container .price {
  text-align: right;
  font: 20px/23px Betm Rounded Medium;
  color: #ffffff;
}
.subscribtions-details-wrap .offer-wrap .introductory-offer-container .price .crossed {
  position: relative;
}
.subscribtions-details-wrap .offer-wrap .introductory-offer-container .price .crossed::before {
  content: "";
  position: absolute;
  top: 43%;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: white;
}
.subscribtions-details-wrap .offer-wrap .introductory-offer-container .free-offer {
  display: flex;
  align-items: center;
  color: #1e285a;
}
.subscribtions-details-wrap .offer-wrap .introductory-offer-container .free-offer h4 {
  font: 40px/45px Betm Rounded Medium;
}
.subscribtions-details-wrap .offer-wrap .introductory-offer-container .free-offer h6 {
  font: 20px/25px Betm Rounded;
}
.subscribtions-details-wrap .long-term-wrap {
  border: 3px solid #e9e3d6;
  border-radius: 10px;
  padding: 40px 40px 13px;
}
.subscribtions-details-wrap .long-term-wrap .header h6 {
  font: 25px/29px Betm Rounded Medium;
  color: #f48040;
  text-transform: uppercase;
}
.subscribtions-details-wrap .long-term-wrap .header .subheader {
  font: 20px/23px Betm Rounded;
  color: #1e285a;
  margin: 21px 0 12px;
}
.subscribtions-details-wrap .long-term-wrap .header .subheader span {
  color: #7a7a7a;
}
.subscribtions-details-wrap .long-term-wrap .header .subheader.subheader-particular {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.subscribtions-details-wrap .long-term-wrap .price {
  font: 59px/56px Betm Rounded;
  font-weight: bold;
  color: #1e285a;
}
.subscribtions-details-wrap .long-term-wrap .price span {
  font: 20px/56px Betm Rounded;
}
.subscribtions-details-wrap .long-term-wrap .price .subprice {
  color: #7a7a7a;
  font: 30px/26px Betm Rounded;
  text-align: right;
}
.subscribtions-details-wrap .long-term-wrap .price .subprice span {
  font: 20px/26px Betm Rounded;
}
.subscribtions-details-wrap .long-term-wrap .price .required {
  font: 49px/64px Betm Rounded;
  color: #f48040;
}
.subscribtions-details-wrap .long-term-wrap .slider-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.subscribtions-details-wrap .long-term-wrap .slider-wrap .price {
  margin-top: 20px;
}
.subscribtions-details-wrap .long-term-wrap .long-term-price-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: baseline;
  margin-top: 40px;
  margin-bottom: 20px;
}
.subscribtions-details-wrap .long-term-wrap .long-term-price-wrap .buttons-wrap {
  display: flex;
}
.subscribtions-details-wrap .long-term-wrap .long-term-price-wrap .buttons-wrap .button {
  width: 240px;
  min-height: 80px;
  padding: 25px 15px;
  font: 25px/25px Betm Rounded Medium;
}
.subscribtions-details-wrap .long-term-wrap .long-term-price-wrap .buttons-wrap .button:first-child {
  margin-right: 10px;
}
.subscribtions-details-wrap .long-term-wrap .reduced-price {
  margin-top: 40px;
  padding: 50px 0 45px;
  border-top: 1px solid #cfcfcf;
}
.subscribtions-details-wrap .long-term-wrap .reduced-price h5 {
  font: 25px/29px Betm Rounded Medium;
  color: #f48040;
  text-transform: uppercase;
}
.subscribtions-details-wrap .long-term-wrap .reduced-price > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.subscribtions-details-wrap .long-term-wrap .reduced-price > div p {
  font: 20px/23px Betm Rounded;
  color: #1e285a;
}
.subscribtions-details-wrap .assurance-wrap {
  margin-top: 60px;
  padding-top: 80px;
  padding-bottom: 67px;
  border-top: #cfcfcf 1px solid;
  border-bottom: #cfcfcf 1px solid;
  font-family: Betm Rounded;
  font-size: 20px;
}
.subscribtions-details-wrap .assurance-wrap .assurance-title {
  margin-top: 0;
}
.subscribtions-details-wrap .assurance-wrap .assurance-description {
  color: #7a7a7a;
  margin-bottom: 30px;
  font-family: Betm Rounded Semilight;
}
.subscribtions-details-wrap .assurance-wrap .assurance-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 3px solid #e9e3d6;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 48px 64px;
}
.subscribtions-details-wrap .assurance-wrap .assurance-content .assurance-content-details {
  margin-right: 15px;
  font-size: 25px;
  color: #1e285a;
}
.subscribtions-details-wrap .assurance-wrap .assurance-content .assurance-content-price {
  display: flex;
  align-items: center;
}
.subscribtions-details-wrap .assurance-wrap .assurance-content .assurance-content-price .price-wrap {
  font-family: Betm Rounded Medium;
  margin-right: 16px;
  color: #f16344;
}
.subscribtions-details-wrap .security-deposit {
  border: 3px solid #e9e3d6;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 21px 43px;
  margin-bottom: 50px;
}
.subscribtions-details-wrap .security-deposit-text {
  display: flex;
  flex-direction: column;
  width: 60%;
}
.subscribtions-details-wrap .security-deposit-text h5 {
  font: 25px/33px Betm Rounded Medium;
  color: #f48040;
  text-transform: uppercase;
}
.subscribtions-details-wrap .security-deposit-text p {
  font: 20px/26px Betm Rounded;
  color: #1e285a;
  margin-top: 13px;
}
.subscribtions-details-wrap .security-deposit-price span {
  font: 20px/26px Betm Rounded;
}
.subscribtions-details-wrap .security-deposit-price .main-price {
  color: #1e285a;
  font: 59px/59px Betm Rounded;
  font-weight: bold;
}
.subscribtions-details-wrap .security-deposit-price .subprice {
  color: #7a7a7a;
  font: 30px/26px Betm Rounded;
}
.subscribtions-details-wrap .promo-wrap {
  border-radius: 10px;
  border: 3px solid #e9e3d6;
  position: relative;
  word-break: break-word;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 51px 48px;
}
.subscribtions-details-wrap .promo-wrap .form-wrap {
  display: flex;
  flex-direction: column;
}
.subscribtions-details-wrap .promo-wrap .form-wrap img {
  margin-left: 20px;
}
@media (max-width: 768px) {
  .subscribtions-details-wrap .promo-wrap .form-wrap img {
    margin-left: 5px;
  }
}
@media (max-width: 768px) {
  .subscribtions-details-wrap .promo-wrap .form-wrap img {
    margin-left: 5px;
  }
}
.subscribtions-details-wrap .promo-wrap .form-wrap input {
  width: 259px;
}
.subscribtions-details-wrap .promo-wrap .form-wrap .input-wrap {
  display: flex;
  align-items: center;
}
.subscribtions-details-wrap .promo-wrap .form-wrap .form-helper-text {
  margin: 3px 14px 0;
  color: #f44336;
  font-size: 0.75rem;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}
.subscribtions-details-wrap .promo-wrap .description-wrap {
  max-width: 33%;
}
.subscribtions-details-wrap .promo-wrap .description-wrap h6 {
  font: 25px/33px Betm Rounded Medium;
  color: #f48040;
  text-transform: uppercase;
}
.subscribtions-details-wrap .promo-wrap .description-wrap p {
  font: 16px/21px Betm Rounded;
  color: #1e285a;
}
.subscribtions-details-wrap .promo-wrap .discount-wrap {
  color: #1e285a;
  display: flex;
  align-items: flex-end;
}
.subscribtions-details-wrap .promo-wrap .discount-wrap div {
  font: 59px/26px Betm Rounded;
  font-weight: bold;
}
.subscribtions-details-wrap .promo-wrap .discount-wrap span {
  font: 20px/26px Betm Rounded;
}
.subscribtions-details-wrap .promo-wrap button {
  position: absolute;
  bottom: -25px;
  right: 50px;
  width: 210px;
  height: 50px;
  border-radius: 24px;
  background: transparent linear-gradient(257deg, #f48040 0%, #ef4746 100%) 0% 0% no-repeat padding-box;
  cursor: pointer;
  outline: none;
  font: 20px/23px Betm Rounded;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 3px solid #e9e3d6;
  border-radius: 10px;
  padding: 36px 36px 45px 27px;
  margin: 30px 0;
  position: relative;
  word-break: break-word;
}
.subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon.second-driver img {
  height: 114px;
  width: 114px;
}
.subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-image {
  width: 163px;
  height: 146px;
  margin-right: 25px;
}
.subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .second-driver-button {
  position: absolute;
  bottom: -25px;
  right: 50px;
  width: 210px;
  height: 50px;
  border-radius: 24px;
  background: transparent linear-gradient(257deg, #f48040 0%, #ef4746 100%) 0% 0% no-repeat padding-box;
  cursor: pointer;
  outline: none;
  font: 20px/23px Betm Rounded;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .second-driver-button.active {
  color: #f48040;
  background: #fff;
  border: 2px solid #f48040;
}
.subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-content h6 {
  font: 25px/29px Betm Rounded Medium;
  color: #f48040;
  text-transform: uppercase;
}
.subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-content p {
  font: 20px/24px Betm Rounded;
  color: #1e285a;
}
@media (max-width: 768px) {
  .subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-content {
    word-break: break-word;
    width: 100%;
  }
}
.subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-price {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font: 39px/45px Betm Rounded Medium;
  color: #1e285a;
  white-space: nowrap;
  min-width: max-content;
}
.subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-price .addon-price-ht-wrap {
  font: 20px/26px Betm Rounded;
}
.subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-price .addon-price-ht-wrap .addon-price-ht {
  font: 39px/45px Betm Rounded Medium;
}
.subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-price .addon-price-ttc-wrap {
  color: #7a7a7a;
  font: 20px/26px Betm Rounded;
}
.subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-price .addon-price-ttc-wrap .addon-price-ttc {
  font: 31px/37px Betm Rounded Medium;
}
.subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-price span {
  font: 20px/26px Betm Rounded;
}
.subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-count {
  position: absolute;
  bottom: -25px;
  right: 50px;
  width: 210px;
  height: 50px;
  border-radius: 24px;
  border: 2px solid #f48040;
  overflow: hidden;
  background: none;
  font: 20px/23px Betm Rounded;
  font-weight: bold;
  color: #ffffff;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-count input {
  padding: 1px 2px;
  border: 1px solid #1e285a;
  color: #1e285a;
  font: 20px Betm Rounded;
  border-radius: 5px;
  width: 62px;
  height: 35px;
  box-sizing: border-box;
  text-align: center;
  /* Firefox */
}
.subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-count input::-webkit-outer-spin-button, .subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-count input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-count input[type=number] {
  -moz-appearance: textfield;
}
.subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-count button {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  color: #f48040;
  font-size: 30px;
  padding: 1px 6px;
  margin: 0 20px;
}
.subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-count span {
  font-size: 18px;
}
@media (max-width: 1280px) {
  .subscribtions-details-wrap .inclusives-wrap {
    width: 100%;
  }
  .subscribtions-details-wrap .offer-wrap {
    width: 100%;
    margin-top: 20px;
  }
}
@media (max-width: 1000px) {
  .subscribtions-details-wrap .long-term-wrap .long-term-price-wrap {
    flex-direction: column;
    align-items: center;
  }
  .subscribtions-details-wrap .long-term-wrap .long-term-price-wrap .buttons-wrap {
    flex-direction: column-reverse;
    margin-bottom: 25px;
  }
  .subscribtions-details-wrap .long-term-wrap .long-term-price-wrap .buttons-wrap .button:not(:first-child) {
    margin-bottom: 15px;
  }
  .subscribtions-details-wrap .long-term-wrap .long-term-price-wrap .buttons-wrap.particular {
    flex-direction: column;
  }
  .subscribtions-details-wrap .long-term-wrap .long-term-price-wrap .buttons-wrap.particular .button:not(:first-child) {
    margin-top: 15px;
  }
  .subscribtions-details-wrap .long-term-wrap .long-term-price-wrap .price .subprice {
    text-align: center;
  }
}
@media (max-width: 768px) {
  .subscribtions-details-wrap {
    padding: 15px 8px 100px;
    margin: 26px 10px 0;
  }
  .subscribtions-details-wrap .scooter-info {
    border-top: none;
    margin-bottom: 5px;
    padding-top: 0;
    padding-bottom: 15px;
    font: 17px/20px Betm Rounded Medium;
  }
  .subscribtions-details-wrap .scooter-info h6 {
    font: 17px/20px Betm Rounded Medium;
  }
  .subscribtions-details-wrap .scooter-info > div {
    font-weight: lighter;
  }
  .subscribtions-details-wrap .scooter-info .semilight {
    font: 17px/23px Betm Rounded Semilight;
  }
  .subscribtions-details-wrap .scooter-title h2 {
    font-size: 19px;
  }
  .subscribtions-details-wrap .scooter-title h2 span {
    font-size: 12px;
  }
  .subscribtions-details-wrap .scooter-title .bonus-eco-text {
    width: 100%;
  }
  .subscribtions-details-wrap > h2,
.subscribtions-details-wrap > div h2 {
    font: 21px/24px Betm Rounded Medium;
    margin: 10px 0 0;
  }
  .subscribtions-details-wrap .divider {
    margin: 5px 0 15px;
    width: 40px;
    height: 4px;
  }
  .subscribtions-details-wrap .promo-wrap {
    padding: 36px 27px 45px;
    margin: 0 0 30px;
  }
  .subscribtions-details-wrap .promo-wrap button {
    right: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
  }
  .subscribtions-details-wrap .promo-wrap .form-wrap {
    width: 100%;
  }
  .subscribtions-details-wrap .promo-wrap .form-wrap > div {
    width: 100%;
  }
  .subscribtions-details-wrap .promo-wrap .description-wrap {
    max-width: unset;
    margin: 20px 0 25px;
    width: 100%;
    justify-content: center;
  }
  .subscribtions-details-wrap .security-deposit {
    padding: 23px 15px;
  }
  .subscribtions-details-wrap .security-deposit .security-deposit-text {
    width: 100%;
  }
  .subscribtions-details-wrap .security-deposit .security-deposit-text h5 {
    font-size: 21px;
    line-height: initial;
  }
  .subscribtions-details-wrap .security-deposit .security-deposit-text p {
    font-size: 17px;
    line-height: initial;
  }
  .subscribtions-details-wrap .security-deposit .security-deposit-price {
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .subscribtions-details-wrap .security-deposit .security-deposit-price .main-price {
    font-size: 49px;
    line-height: initial;
  }
  .subscribtions-details-wrap .discount-wrap {
    width: 100%;
    justify-content: center;
  }
  .subscribtions-details-wrap .lists-wrap .inclusives-wrap {
    min-width: unset;
    padding: 20px 14px;
  }
  .subscribtions-details-wrap .lists-wrap .inclusives-wrap h5 {
    font: 21px/24px Betm Rounded Medium;
    margin-bottom: 10px;
    word-break: break-word;
  }
  .subscribtions-details-wrap .lists-wrap .inclusives-wrap ul {
    font: 17px/21px Betm Rounded Medium;
  }
  .subscribtions-details-wrap .lists-wrap .inclusives-wrap ul li img {
    height: 17px;
  }
  .subscribtions-details-wrap .lists-wrap .offer-wrap {
    margin-top: 20px;
    min-width: unset;
    width: 100%;
    padding: 20px 14px;
  }
  .subscribtions-details-wrap .lists-wrap .offer-wrap h5 {
    font: 21px/24px Betm Rounded Medium;
    margin-bottom: 10px;
  }
  .subscribtions-details-wrap .lists-wrap .offer-wrap ul {
    font: 17px/21px Betm Rounded Medium;
  }
  .subscribtions-details-wrap .lists-wrap .offer-wrap .introductory-offer-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  .subscribtions-details-wrap .lists-wrap .offer-wrap .introductory-offer-container .price {
    text-align: left;
    width: 40%;
  }
  .subscribtions-details-wrap .lists-wrap .offer-wrap .introductory-offer-container .price .crossed {
    font-size: 30px;
  }
  .subscribtions-details-wrap .lists-wrap .offer-wrap .introductory-offer-container .free-offer {
    width: 60%;
    display: initial;
    text-align: right;
  }
  .subscribtions-details-wrap .lists-wrap .offer-wrap .introductory-offer-container .free-offer h4 {
    font-size: 30px;
    line-height: initial;
  }
  .subscribtions-details-wrap .lists-wrap .offer-wrap .introductory-offer-container .free-offer h6 {
    font-size: 20px;
    line-height: initial;
  }
  .subscribtions-details-wrap .long-term-wrap {
    padding: 20px 15px;
    overflow: hidden;
  }
  .subscribtions-details-wrap .long-term-wrap .header h6 {
    font: 21px/24px Betm Rounded Medium;
  }
  .subscribtions-details-wrap .long-term-wrap .header .subheader {
    font: 17px/21px Betm Rounded;
  }
  .subscribtions-details-wrap .long-term-wrap .header .subheader.subheader-particular {
    flex-direction: column;
  }
  .subscribtions-details-wrap .long-term-wrap .header .subheader.subheader-particular > div:first-child {
    margin-bottom: 10px;
  }
  .subscribtions-details-wrap .long-term-wrap .slider-wrap {
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 10px;
  }
  .subscribtions-details-wrap .long-term-wrap .slider-wrap .price {
    margin-top: 10px;
    font-size: 40px;
  }
  .subscribtions-details-wrap .long-term-wrap .long-term-price-wrap {
    flex-wrap: nowrap;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .subscribtions-details-wrap .long-term-wrap .long-term-price-wrap .buttons-wrap .button {
    font: 20px/20px Betm Rounded Medium;
  }
  .subscribtions-details-wrap .long-term-wrap .long-term-price-wrap .price {
    font-size: 49px;
  }
  .subscribtions-details-wrap .long-term-wrap .reduced-price h5 {
    font-size: 20px;
  }
  .subscribtions-details-wrap .long-term-wrap .reduced-price > div {
    margin-top: 5px;
    flex-direction: column;
  }
  .subscribtions-details-wrap .long-term-wrap .reduced-price > div p {
    font: 17px/19px Betm Rounded;
  }
  .subscribtions-details-wrap .long-term-wrap .reduced-price > div .price {
    font-size: 49px;
    margin-top: 10px;
  }
  .subscribtions-details-wrap .long-term-wrap .reduced-price > div .price .subprice {
    text-align: center;
  }
  .subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon {
    flex-direction: column;
  }
  .subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-content h6 {
    font: 21px/24px Betm Rounded Medium;
  }
  .subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-content p {
    font: 17px/19px Betm Rounded;
  }
  .subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .second-driver-button {
    right: unset;
  }
  .subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-price {
    margin-left: 0;
    align-items: center;
    margin-top: 20px;
    font: 29px/35px Betm Rounded Medium;
  }
  .subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-price .addon-price-ht-wrap {
    font: 17px/23px Betm Rounded;
  }
  .subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-price .addon-price-ht-wrap .addon-price-ht {
    font: 29px/35px Betm Rounded Medium;
  }
  .subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-price .addon-price-ttc-wrap {
    font: 17px/23px Betm Rounded;
  }
  .subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-price .addon-price-ttc-wrap .addon-price-ttc {
    font: 23px/29px Betm Rounded Medium;
  }
  .subscribtions-details-wrap .addons-wrap .addons-wrap-list .addon .addon-count {
    right: unset;
  }
  .subscribtions-details-wrap .assurance-wrap {
    padding-top: 30px;
  }
  .subscribtions-details-wrap .assurance-wrap .assurance-description {
    font-size: 17px;
  }
  .subscribtions-details-wrap .assurance-wrap .assurance-content {
    padding: 24px;
  }
  .subscribtions-details-wrap .assurance-wrap .assurance-content .assurance-content-details {
    font-size: 18px;
  }
  .subscribtions-details-wrap .assurance-wrap .assurance-content .assurance-content-price .price-wrap {
    font-size: 17px;
  }
}
@media (max-width: 550px) {
  .subscribtions-details-wrap .assurance-wrap .assurance-content {
    text-align: center;
    flex-direction: column;
  }
  .subscribtions-details-wrap .assurance-wrap .assurance-content .assurance-content-details {
    margin-bottom: 10px;
    margin-right: 0;
  }
}

.personal-form-wrap {
  background: #fff;
  max-width: 1393px;
  min-height: 100vh;
  border-radius: 10px 10px 0px 0px;
  margin: 50px auto 0;
  padding-bottom: 100px;
}
.personal-form-wrap .header {
  color: #1e285a;
  text-align: center;
  font: 39px/45px Betm Rounded Medium;
}
.personal-form-wrap .indent-30 {
  margin-top: 30px;
}
.personal-form-wrap .divider {
  width: 80px;
  height: 8px;
  background: #f48040 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin: 10px auto 0;
}
.personal-form-wrap .required-wrapper {
  max-width: 432px;
  margin: 0 auto;
}
.personal-form-wrap .required {
  font: 11px/14px Betm Rounded Medium;
  color: #1e285a;
  padding-top: 30px;
  display: block;
}
.personal-form-wrap .required > span {
  font: 11px/14px Betm Rounded Medium;
  color: #f48040;
}
.personal-form-wrap form {
  max-width: 432px;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
}
.personal-form-wrap form .surname-wrap {
  display: flex;
  justify-content: space-between;
}
.personal-form-wrap form .surname-wrap .MuiInputBase-root {
  font: 18px/21px Betm Rounded;
  color: #1e285a;
}
.personal-form-wrap form .surname-wrap > div:nth-child(2) {
  width: 75%;
}
.personal-form-wrap form .date-input-container {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.personal-form-wrap form .date-input-container .date-input-error-wrap {
  width: 100%;
  color: #f44336;
  margin-top: 3px;
  margin-left: 14px;
  margin-right: 14px;
  font: 0.75rem Roboto, sans-serif;
  line-height: 1.66;
  font-weight: 400;
}
.personal-form-wrap form .date-input-container .date-input:not(:last-child) {
  margin-right: 12px;
}
.personal-form-wrap form .date-input-container .date-input {
  box-sizing: border-box;
  padding: 20px 15px;
  border: 1px solid #1e285a;
  border-radius: 4px;
  outline: none;
  font-family: Betm Rounded;
  font-size: 18px;
  color: #1e285a;
}
.personal-form-wrap form .date-input-container .date-input::placeholder {
  color: #1e285a;
  opacity: 0.5;
}
.personal-form-wrap form .date-input-container .date-input:focus {
  outline: none;
}
.personal-form-wrap form .date-input-container .date-input.error {
  color: #f44336;
  border: 1px solid #f44336;
}
.personal-form-wrap form .date-input-container .date-input-day {
  width: 64px;
  text-align: center;
}
.personal-form-wrap form .date-input-container .date-input-month {
  width: 64px;
  text-align: center;
}
.personal-form-wrap form .date-input-container .date-input-year {
  width: 128px;
}
.personal-form-wrap form .phone-input-container {
  margin-top: 10px;
  position: relative;
  font-family: Betm Rounded;
  font-size: 18px;
}
.personal-form-wrap form .phone-input-container .phone-input-wrap {
  border: 1px solid #1e285a;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.personal-form-wrap form .phone-input-container .phone-input-wrap.not-valid {
  border: 1px solid #f44336;
}
.personal-form-wrap form .phone-input-container .phone-input-wrap .phone-input-dropdown-button {
  cursor: pointer;
  height: 58px;
  display: flex;
  align-items: center;
  padding: 21px 19px;
  background-color: #ffffff;
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-right: 1px solid #1e285a;
}
.personal-form-wrap form .phone-input-container .phone-input-wrap .phone-input-dropdown-button.not-valid {
  border-right: 1px solid #f44336;
}
.personal-form-wrap form .phone-input-container .phone-input-wrap .phone-input-dropdown-button:focus {
  background-color: #e0e0e0;
  outline: none;
}
.personal-form-wrap form .phone-input-container .phone-input-wrap .phone-input-dropdown-button:hover {
  background-color: #e0e0e0;
}
.personal-form-wrap form .phone-input-container .phone-input-wrap .phone-input-dropdown-button .dropdown-button-text {
  width: 40px;
  font-family: Betm Rounded;
  font-size: 18px;
  color: #1e285a;
}
.personal-form-wrap form .phone-input-container .phone-input-wrap .phone-input-dropdown-button .dropdown-button-text.not-valid {
  color: #f44336;
}
.personal-form-wrap form .phone-input-container .phone-input-wrap .phone-input-dropdown-button .arrow-down {
  margin-right: 15px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #1e285a;
}
.personal-form-wrap form .phone-input-container .phone-input-wrap .phone-inputs-wrap {
  font-family: Betm Rounded;
  font-size: 18px;
  box-sizing: border-box;
  height: 59px;
  width: 100%;
  padding: 21px 19px;
  color: #1e285a;
  display: flex;
  align-items: center;
}
.personal-form-wrap form .phone-input-container .phone-input-wrap .phone-inputs-wrap .phone-input {
  width: 100%;
  font-family: Betm Rounded;
  font-size: 18px;
  color: #1e285a;
  border: none;
  outline: none;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
.personal-form-wrap form .phone-input-container .phone-input-wrap .phone-inputs-wrap .phone-input:focus {
  outline: none;
}
.personal-form-wrap form .phone-input-container .phone-input-wrap .phone-inputs-wrap .phone-input::-webkit-outer-spin-button, .personal-form-wrap form .phone-input-container .phone-input-wrap .phone-inputs-wrap .phone-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.personal-form-wrap form .phone-input-container .phone-input-wrap .phone-inputs-wrap .phone-input[type=number] {
  -moz-appearance: textfield;
}
.personal-form-wrap form .phone-input-container .country-phone-select-container {
  z-index: 1;
  width: 65%;
  min-width: 65%;
  position: absolute;
  top: 68px;
  background: #ffffff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.personal-form-wrap form .phone-input-container .country-phone-select-container .country-phone-search {
  display: flex;
  align-items: center;
  padding: 13px 17px;
  border-bottom: 1px solid #e9e3d6;
}
.personal-form-wrap form .phone-input-container .country-phone-select-container .country-phone-search .search-icon {
  margin-right: 18px;
  width: 22px;
  height: 22px;
  background-image: url("../images/icons/picto-search.svg");
}
.personal-form-wrap form .phone-input-container .country-phone-select-container .country-phone-search .search-input {
  font-family: Betm Rounded;
  font-size: 18px;
  flex-grow: 1;
  outline: none;
  border: none;
}
.personal-form-wrap form .phone-input-container .country-phone-select-container .country-phone-search .search-input:focus {
  outline: none;
}
.personal-form-wrap form .phone-input-container .country-phone-select-container .country-phone-list {
  overflow-y: scroll;
  height: 270px;
}
.personal-form-wrap form .phone-input-container .country-phone-select-container .country-phone-list li {
  padding: 12px 24px;
  cursor: pointer;
}
.personal-form-wrap form .phone-input-container .country-phone-select-container .country-phone-list li:focus {
  background-color: rgba(0, 0, 0, 0.08);
  outline: none;
}
.personal-form-wrap form .phone-input-container .country-phone-select-container .country-phone-list li:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
.personal-form-wrap form .phone-input-container .country-phone-select-container .country-phone-list li .country-name {
  color: #1e285a;
  font-weight: 700;
}
.personal-form-wrap form .phone-input-container .country-phone-select-container .country-phone-list li .country-phone-number {
  color: #9b9b9b;
}
.personal-form-wrap form .country-select-container .country-select .country-select-arrow {
  margin: 13px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #1e285a;
}
.personal-form-wrap form .country-select-container .country-select-error {
  line-height: 1.66;
  color: #f44336;
  margin-top: 3px;
  margin-left: 14px;
  margin-right: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
}
.personal-form-wrap form .MuiInputBase-root {
  font: 18px/21px Betm Rounded;
  color: #1e285a;
}
.personal-form-wrap form h4 {
  font: 25px/29px Betm Rounded Medium;
  color: #1e285a;
  margin-top: 24px;
}
.personal-form-wrap form h4 span {
  color: #f48040;
}
.personal-form-wrap form .invalid-phone {
  line-height: 1.66;
  color: #f44336;
  margin-top: 3px;
  margin-left: 14px;
  margin-right: 14px;
  font: 0.75rem Roboto, sans-serif;
  font-weight: 400;
}
.personal-form-wrap form > label {
  margin-top: 20px;
  padding-left: 10px;
}
.personal-form-wrap form > label .label {
  margin-left: 10px;
  font: 24px/31px Betm Rounded Medium;
  color: #1e285a;
}
@media (max-width: 768px) {
  .personal-form-wrap {
    margin: 0 10px;
    padding: 0 20px 60px 10px;
  }
  .personal-form-wrap form .phone-input-container .country-phone-select-container {
    width: 100%;
    min-width: 100%;
  }
  .personal-form-wrap form .date-input-container .date-input:not(:last-child) {
    margin-right: 7px;
  }
}

.subscription-second-driver-wrap > span {
  font: 11px/12px Betm Rounded Medium;
  color: #1e285a;
  margin-bottom: 24px;
}
.subscription-second-driver-wrap > span > span {
  color: #f48040;
}
.subscription-second-driver-wrap .license_label {
  font: 20px/24px Betm Rounded;
  color: #1e285a;
}
.subscription-second-driver-wrap .license_label .semilight {
  font: 20px/24px Betm Rounded Semilight;
}
.subscription-second-driver-wrap .label {
  height: 18px;
}

.subscribtions-terms-wrap {
  background: #fff;
  max-width: 1393px;
  display: flex;
  flex-direction: column;
}
.subscribtions-terms-wrap > span {
  font: 11px/12px Betm Rounded Medium;
  color: #1e285a;
  margin-bottom: 24px;
}
.subscribtions-terms-wrap > span > span {
  color: #f48040;
}
.subscribtions-terms-wrap h6 {
  font: 25px/29px Betm Rounded Medium;
  color: #1e285a;
}
.subscribtions-terms-wrap h6 span {
  color: #f48040;
}
.subscribtions-terms-wrap .license-type {
  margin: 25px 0;
}
.subscribtions-terms-wrap .license-type .license_label {
  font: 20px/24px Betm Rounded;
  color: #1e285a;
}
.subscribtions-terms-wrap .license-type .license_label .semilight {
  font: 20px/24px Betm Rounded Semilight;
}
.subscribtions-terms-wrap .license-type .label {
  height: 18px;
}
.subscribtions-terms-wrap .accidents-wrap {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
}
.subscribtions-terms-wrap .accidents-wrap > ul li {
  margin-top: 18px;
  font: 20px/26px Betm Rounded;
  color: #1e285a;
  display: flex;
  justify-content: space-between;
}
.subscribtions-terms-wrap .accidents-wrap > ul li .checkbox-wrap {
  display: flex;
  justify-content: flex-end;
}
.subscribtions-terms-wrap .accidents-wrap > ul li .checkbox-wrap > label {
  padding: 0 0 12px;
}
.subscribtions-terms-wrap .accidents-wrap > ul li .checkbox-wrap > label .label {
  height: 18px;
}
.subscribtions-terms-wrap .accidents-wrap > ul li .checkbox-wrap span {
  font: 20px/26px Betm Rounded;
}
.subscribtions-terms-wrap .label {
  margin-left: 20px;
  font: 20px/23px Betm Rounded;
  color: #1e285a;
}
.subscribtions-terms-wrap .label .star {
  color: #f48040;
}
.subscribtions-terms-wrap .label a {
  text-decoration: underline;
  color: #1e285a;
}
.subscribtions-terms-wrap > label {
  margin-top: 20px;
}
@media (max-width: 768px) {
  .subscribtions-terms-wrap > span {
    text-align: center;
  }
  .subscribtions-terms-wrap.content {
    padding: 15px 10px 121px;
  }
  .subscribtions-terms-wrap.content label {
    margin-left: 20px;
  }
  .subscribtions-terms-wrap h6 {
    font: 17px/19px Betm Rounded Medium;
  }
  .subscribtions-terms-wrap .license-type .license_label {
    font: 17px/19px Betm Rounded;
  }
  .subscribtions-terms-wrap .license-type .license_label .semilight {
    font: 17px/19px Betm Rounded Semilight;
  }
  .subscribtions-terms-wrap .accidents-wrap > ul li {
    flex-direction: column;
    align-items: center;
  }
  .subscribtions-terms-wrap .accidents-wrap > ul li .text {
    width: 100%;
    font: 17px/19px Betm Rounded;
  }
  .subscribtions-terms-wrap .accidents-wrap > ul li .checkbox-wrap {
    margin-top: 20px;
  }
  .subscribtions-terms-wrap .accidents-wrap > ul li .checkbox-wrap span {
    font: 17px/19px Betm Rounded;
  }
  .subscribtions-terms-wrap .label {
    font: 17px/19px Betm Rounded;
  }
}

.subscription-modal {
  padding: 29px 26px 13px;
  max-width: 487px;
}
.subscription-modal .buttons-wrap {
  margin-top: 35px;
  display: flex;
  justify-content: space-around;
}
.subscription-modal .buttons-wrap button {
  width: 183px;
  height: 48px;
  cursor: pointer;
  border: none;
  border-radius: 20px;
  font: 20px/23px Betm Rounded;
  font-weight: bold;
  outline: none;
}
.subscription-modal .buttons-wrap .cancel-button {
  background: none;
  border: 1px solid #f48040;
  margin-right: 5px;
  color: #f48040;
}
.subscription-modal .buttons-wrap .next-button {
  background: transparent linear-gradient(257deg, #f48040 0%, #ef4746 100%) 0% 0% no-repeat padding-box;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}
@media (max-width: 768px) {
  .subscription-modal {
    max-width: unset;
  }
}

.payment-wrap h4 {
  font: 25px/29px Betm Rounded Medium;
  color: #1e285a;
  margin-bottom: 14px;
}
.payment-wrap .title {
  margin-bottom: 12px;
}
.payment-wrap .subtitle {
  font: 400 20px/23px Betm Rounded;
  color: #1e285a;
}
.payment-wrap .subtitle strong {
  font-weight: 600;
}
.payment-wrap .cards-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 12px 0 32px;
}
.payment-wrap .visa-img {
  width: 62px;
  height: 20px;
}
.payment-wrap .mastercard-img {
  height: 34px;
  width: 42px;
}
.payment-wrap .payment-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
  max-width: 374px;
  height: 36px;
  border-radius: 25px;
  padding: 8px 12px;
  font: 600 18px/21px Betm Rounded;
  color: #ffffff;
  background: linear-gradient(225deg, #f48040 0%, #ff4745 100%);
}
.payment-wrap .payment-button:disabled {
  cursor: progress;
}
.payment-wrap .notification-wrap {
  margin: 28px 0 20px;
}
.payment-wrap .bank-details {
  font: 25px/33px Betm Rounded Medium;
  color: #1e285a;
}
.payment-wrap .payment-inputs-wrap {
  display: flex;
  justify-content: space-between;
}
.payment-wrap .payment-inputs-wrap > div:first-child {
  width: 60%;
}
.payment-wrap .payment-inputs-wrap > div:nth-child(2) {
  width: 35%;
}
@media (max-width: 768px) {
  .payment-wrap h4 {
    font: 17px/20px Betm Rounded Medium;
  }
  .payment-wrap .bank-details {
    font: 17px/19px Betm Rounded Medium;
  }
  .payment-wrap .payment-inputs-wrap {
    flex-direction: column;
  }
  .payment-wrap .payment-inputs-wrap > div {
    width: 100% !important;
  }
}

.uploaders-wrap {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}
.uploaders-wrap .uploader {
  width: 200px;
  min-height: 299px;
  background: #FFFFFF36 0% 0% no-repeat padding-box;
  border: 2px dashed #E9E3D6;
  border-radius: 10px;
  padding: 27px 9px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.uploaders-wrap .uploader .images-mobile {
  display: none;
}
.uploaders-wrap .uploader .image-wrap {
  box-shadow: 0px 3px 9px #00000053;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  width: 103px;
  height: 103px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.uploaders-wrap .uploader .image-wrap .file-image {
  margin-left: -12px;
}
.uploaders-wrap .uploader .image-wrap .icon-check {
  display: none;
}
.uploaders-wrap .uploader .image-wrap .icon-check.active {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 55px;
  left: -8px;
  background: #fff;
  width: 50px;
  height: 50px;
  box-shadow: 0px 3px 9px #00000053;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
}
.uploaders-wrap .uploader .image-wrap .icon-check.active > img {
  position: absolute;
}
.uploaders-wrap .uploader .headers-wrap {
  min-height: 112px;
}
.uploaders-wrap .uploader h4 {
  font: 20px/23px Betm Rounded Medium;
  color: #1E285A;
  margin-top: 33px;
  text-align: center;
}
.uploaders-wrap .uploader h6 {
  text-align: center;
  font: 10px/11px Betm Rounded;
  font-weight: lighter;
  color: #1E285A;
}
.uploaders-wrap .uploader .valid-document {
  font: 16px/21px Betm Rounded Medium;
  color: #69e6b6;
  margin-top: 22px;
}
.uploaders-wrap .uploader .not-verified-document {
  font: 16px/21px Betm Rounded Medium;
  color: #1E285A;
  margin-top: 22px;
}
.uploaders-wrap .uploader .missing-document {
  font: 16px/21px Betm Rounded Medium;
  color: #F48040;
  margin-top: 22px;
}
.uploaders-wrap .uploader .warning-document {
  font: 16px/21px Betm Rounded Medium;
  color: #B50F0F;
  margin-top: 22px;
}
.uploaders-wrap .uploader ul {
  margin: 21px 0 0;
  width: 80%;
}
.uploaders-wrap .uploader ul li {
  font: 10px/11px Betm Rounded;
  color: #1E285A;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.uploaders-wrap .uploader ul li div {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.uploaders-wrap .uploader ul li button {
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
}
.uploaders-wrap .uploader ul li button svg {
  color: #1E285A;
  font-size: 10px;
}

@media (max-width: 768px) {
  .uploaders-wrap {
    margin-top: 0;
    flex-direction: column;
    align-items: center;
  }
  .uploaders-wrap .uploader {
    margin: 10px 0 0;
    width: 100%;
    box-sizing: border-box;
    min-height: 76px;
    padding: 14px 18px;
    flex-direction: column;
    align-items: flex-start;
  }
  .uploaders-wrap .uploader .image-wrap {
    display: none;
  }
  .uploaders-wrap .uploader .headers-wrap {
    min-height: unset;
  }
  .uploaders-wrap .uploader .mobile-wrapper {
    display: flex;
    width: 100%;
  }
  .uploaders-wrap .uploader .mobile-wrapper .images-mobile {
    display: flex;
    justify-content: space-between;
    width: 105px;
    min-width: 105px;
  }
  .uploaders-wrap .uploader .mobile-wrapper .images-mobile .image-mobile-scroll {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .uploaders-wrap .uploader .mobile-wrapper .images-mobile .image-mobile-wrap {
    cursor: pointer;
  }
  .uploaders-wrap .uploader .mobile-wrapper .images-mobile .image-mobile-wrap {
    width: 48px;
    height: 48px;
    border-radius: 5px;
    box-shadow: 0px 3px 9px #00000053;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .uploaders-wrap .uploader .mobile-wrapper .images-mobile .image-mobile-wrap button {
    background: none;
  }
  .uploaders-wrap .uploader .mobile-wrapper .headers-wrap {
    margin-left: 7px;
  }
  .uploaders-wrap .uploader .document-size {
    display: none;
  }
  .uploaders-wrap .uploader h4 {
    margin: 0;
    font: 14px/15px Betm Rounded Medium;
    text-align: left;
  }
  .uploaders-wrap .uploader h6 {
    text-align: left;
    font: 12px/13px Betm Rounded Semilight;
  }
  .uploaders-wrap .uploader ul {
    padding-top: 12px;
    margin: 0;
    width: 100%;
  }
  .uploaders-wrap .uploader ul li {
    padding: 11px 0;
    font: 14px/15px Betm Rounded;
    border-top: 1px solid #E9E3D6;
  }
  .uploaders-wrap .uploader ul li button svg {
    font-size: 24px;
  }
}
.contract-wrap {
  display: flex;
  flex-direction: column;
}
.contract-wrap > p {
  font: 20px/23px Betm Rounded;
  color: #1E285A;
  margin-top: 10px;
  margin-bottom: 10px;
}
.contract-wrap > p .link {
  color: #1E285A;
  text-decoration: underline;
}
.contract-wrap > p .link:hover, .contract-wrap > p .link:active, .contract-wrap > p .link:visited {
  color: #1E285A;
}
.contract-wrap > p span {
  text-decoration: underline;
}
.contract-wrap h4 {
  font: 25px/29px Betm Rounded;
  color: #F48040;
  text-transform: uppercase;
  margin-top: 30px;
}
.contract-wrap .contract {
  word-break: break-word;
  display: flex;
  border: 3px solid #E9E3D6;
  border-radius: 10px;
  padding: 40px 60px;
  justify-content: space-between;
  align-items: flex-start;
}
.contract-wrap .contract .contract-content {
  margin-right: 10px;
}
.contract-wrap .contract .contract-content h4 {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.contract-wrap .contract .contract-content h4 > svg {
  margin-left: 20px;
}
.contract-wrap .contract .contract-content .contract-patience {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font: 20px/23px Betm Rounded;
  color: #1E285A;
}
.contract-wrap .contract .contract-content .contract-patience > span {
  margin-right: 1px;
  animation: breath 1000ms ease-in-out infinite;
}
.contract-wrap .contract .contract-content a {
  font: 20px/23px Betm Rounded Medium;
  color: #1E285A;
  text-decoration: underline;
}
.contract-wrap .contract .image-wrap {
  height: 74px;
  width: 74px;
  position: relative;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 3px 9px #00000053;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  padding: 14px;
}
.contract-wrap .contract .image-wrap .label {
  position: absolute;
  background: #fff;
  bottom: 7px;
  left: 11px;
}
.contract-wrap ul {
  margin-top: 10px;
}
.contract-wrap ul li {
  border: 1px solid #E9E3D6;
  display: flex;
  margin-top: 10px;
}
.contract-wrap ul li div {
  display: flex;
  align-items: center;
}
.contract-wrap ul li .text {
  background: #E9E3D666 0% 0% no-repeat padding-box;
  font: 20px/23px Betm Rounded Medium;
  color: #1E285A;
  width: 100%;
  padding: 0 15px;
}
.contract-wrap ul li .sign {
  justify-content: center;
  height: 50px;
  width: 50px;
  color: #F48040;
  font-size: 30px;
}
@media (max-width: 768px) {
  .contract-wrap .contract {
    padding: 20px;
  }
  .contract-wrap .contract .contract-patience {
    font: 17px/19px Betm Rounded;
  }
  .contract-wrap > p {
    font: 17px/19px Betm Rounded;
  }
  .contract-wrap h4 {
    font: 21px/24px Betm Rounded;
  }
}

@keyframes breath {
  from, to {
    transform: scale(0.9);
  }
  40% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(1);
  }
  to {
    transform: scale(0.9);
  }
}
.email_not_verified {
  padding: 10px 18px;
  box-sizing: border-box;
  position: absolute;
  min-width: 425px;
  height: 46px;
  right: 16px;
  bottom: 16px;
  background: #ffe4e1;
  border-radius: 8px;
  font: 20px/23px Betm Rounded Medium;
  color: #b50f0f;
  z-index: 2;
}

.account-wrap {
  display: flex;
}
.account-wrap .side-menu {
  position: relative;
  float: left;
  top: 0;
  bottom: 0;
  width: 346px;
  padding: 25px 0 0 30px;
}
.account-wrap .side-menu .clement {
  max-width: 200px;
  min-width: 100px;
  width: fit-content;
  height: 36px;
  background: #fff;
  border: 2px solid #e9e3d6;
  border-radius: 18px;
  font: 18px/24px Betm Rounded;
  color: #1e285a;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 13px 0 5px;
}
.account-wrap .side-menu .clement img {
  height: 26px;
}
.account-wrap .side-menu .clement span {
  margin-left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}
.account-wrap .side-menu .contact-us {
  position: fixed;
  bottom: 30px;
  text-decoration: underline;
  border: none;
  background: none;
  font: 20px/26px Betm Rounded Medium;
  color: #1e285a;
  cursor: pointer;
}
.account-wrap .side-menu .contact-us a,
.account-wrap .side-menu .contact-us a:visited,
.account-wrap .side-menu .contact-us a:active {
  color: #1e285a;
}
.account-wrap .side-menu nav {
  display: flex;
  flex-direction: column;
}
.account-wrap .side-menu nav a {
  font: 20px/23px Betm Rounded Medium;
  color: #1e285a;
  margin-top: 24px;
  text-decoration: none;
}
.account-wrap .side-menu nav a.active {
  color: #f48040;
}
.account-wrap .common-wrap.common-account {
  width: 100%;
  padding-bottom: 0;
}
.account-wrap .common-wrap.common-account .content {
  max-width: 780px;
  padding: 65px 56px;
  min-height: 100vh;
}
.account-wrap .common-wrap.common-account .content .header {
  text-align: left;
}
.account-wrap .common-wrap.common-account .content .divider {
  margin: 10px 0 0;
}
.account-wrap .common-wrap.common-account .content .help-text {
  font: 20px/23px Betm Rounded Medium;
  letter-spacing: 0;
  margin: 42px 0 0;
  color: #9b9b9b;
}
.account-wrap .common-wrap.common-account .content .bank-details {
  font: 25px/33px Betm Rounded Medium;
  color: #1e285a;
}
.account-wrap .common-wrap.common-account .content .error-page {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #1e285a;
}
.account-wrap .common-wrap.common-account .content .error-page h1 {
  font: 64px/80px Betm Rounded;
  margin-top: 62px;
}
.account-wrap .common-wrap.common-account .content .error-page h3 {
  font: 26px/40px Betm Rounded;
  margin-bottom: 32px;
}
.account-wrap .common-wrap.common-account .content .error-page .contact {
  margin: 48px 0 24px;
}
.account-wrap .common-wrap.common-account .content .error-page p {
  font: 20px/30px Betm Rounded;
}
.account-wrap .common-wrap.common-account .content .error-page a.link {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 260px;
  width: 310px;
  height: 48px;
  background: linear-gradient(189.13deg, #f48040 0%, #ef4746 100%);
  border-radius: 100px;
  border: none;
  font: 20px/26px Betm Rounded Medium;
  color: #fff;
  margin: 24px auto 0;
  cursor: pointer;
  outline: none;
}
.account-wrap .common-wrap.common-account .content .reset-password {
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.account-wrap .common-wrap.common-account .content .reset-password button {
  white-space: nowrap;
  min-width: max-content;
  height: 48px;
  background: transparent linear-gradient(261deg, #f48040 0%, #ef4746 100%) 0 0 no-repeat padding-box;
  border-radius: 24px;
  font: 20px/26px Betm Rounded Medium;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
}
.account-wrap .common-wrap.common-account .content .reset-password .reset-password-check-mail {
  margin-top: 10px;
  width: 100%;
  font: 20px/26px Betm Rounded Medium;
  color: #1e285a;
}
.account-wrap .common-wrap.common-account .content .payment-inputs-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.account-wrap .common-wrap.common-account .content .payment-inputs-wrap button {
  width: 310px;
  max-width: 100%;
  height: 48px;
  margin: 20px auto 0;
  background: transparent linear-gradient(261deg, #f48040 0%, #ef4746 100%) 0 0 no-repeat padding-box;
  border-radius: 24px;
  font: 20px/26px Betm Rounded Medium;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
}
.account-wrap .common-wrap.common-account .content .dashboard-list .dashboard-item {
  text-decoration: none;
  border: 1px solid #e9e3d6;
  border-radius: 4px;
  display: flex;
  height: 50px;
  margin: 12px 0 0;
}
.account-wrap .common-wrap.common-account .content .dashboard-list .dashboard-item .list-text {
  font: 20px/23px Betm Rounded Medium;
  color: #1e285a;
  width: 100%;
  padding: 0 15px;
  background: #e9e3d666;
  display: flex;
  align-items: center;
}
.account-wrap .common-wrap.common-account .content .dashboard-list .dashboard-item .list-status {
  padding: 0 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 30%;
  font: 16px/19px Betm Rounded Medium;
}
.account-wrap .common-wrap.common-account .content .dashboard-list .dashboard-item .list-status.ok, .account-wrap .common-wrap.common-account .content .dashboard-list .dashboard-item .list-status.validated {
  color: #69e5b6;
}
.account-wrap .common-wrap.common-account .content .dashboard-list .dashboard-item .list-status.warning {
  text-decoration: underline;
  color: #f48040;
}
.account-wrap .common-wrap.common-account .content .dashboard-list .dashboard-item .list-status.error {
  text-decoration: underline;
  color: #b50f0f;
}
.account-wrap .common-wrap.common-account .content .dashboard-list .dashboard-item .list-icon {
  min-width: 50px;
  border-left: 1px solid #e9e3d6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.account-wrap .common-wrap.common-account .content .payment-data-wrap {
  border-top: 1px solid #cfcfcf;
  border-bottom: 1px solid #cfcfcf;
  display: flex;
  padding: 20px 0;
  margin: 40px 0 20px;
}
.account-wrap .common-wrap.common-account .content .payment-data-wrap-item {
  color: #1e285a;
}
.account-wrap .common-wrap.common-account .content .payment-data-wrap-item:first-child {
  width: 70%;
}
.account-wrap .common-wrap.common-account .content .payment-data-wrap-item h5 {
  font: 20px/26px Betm Rounded Medium;
}
.account-wrap .common-wrap.common-account .content .payment-data-wrap-item h6 {
  font: 20px/26px Betm Rounded Semilight;
}
.account-wrap .common-wrap.common-account .content .personal-wrap {
  display: flex;
  flex-direction: column;
}
.account-wrap .common-wrap.common-account .content .personal-wrap h5 {
  font: 20px/23px Betm Rounded;
  font-weight: bold;
  color: #9b9b9b;
  margin-top: 43px;
}
.account-wrap .common-wrap.common-account .content .personal-wrap > div {
  color: #1e285a;
  border-bottom: 1px solid #cfcfcf;
  padding: 22px 0 17px;
}
.account-wrap .common-wrap.common-account .content .personal-wrap > div h6 {
  font: 20px/23px Betm Rounded Medium;
}
.account-wrap .common-wrap.common-account .content .personal-wrap > div p,
.account-wrap .common-wrap.common-account .content .personal-wrap > div .my-account-phone-wrap {
  font: 20px/23px Betm Rounded;
  font-weight: 100;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.account-wrap .common-wrap.common-account .content .personal-wrap > div p .phone-wrap-actions,
.account-wrap .common-wrap.common-account .content .personal-wrap > div .my-account-phone-wrap .phone-wrap-actions {
  display: flex;
  align-items: center;
}
.account-wrap .common-wrap.common-account .content .personal-wrap > div p .phone-wrap-actions div,
.account-wrap .common-wrap.common-account .content .personal-wrap > div .my-account-phone-wrap .phone-wrap-actions div {
  display: flex;
  align-items: center;
}
.account-wrap .common-wrap.common-account .content .personal-wrap > div p .phone-wrap-actions .phone-wrap-ok-action,
.account-wrap .common-wrap.common-account .content .personal-wrap > div .my-account-phone-wrap .phone-wrap-actions .phone-wrap-ok-action {
  margin-right: 10px;
}
.account-wrap .common-wrap.common-account .content .personal-wrap > div p img,
.account-wrap .common-wrap.common-account .content .personal-wrap > div .my-account-phone-wrap img {
  width: 24px;
  height: 24px;
  cursor: pointer;
  padding: 5px;
}
.account-wrap .common-wrap.common-account .content .personal-wrap > div p .invalid-phone,
.account-wrap .common-wrap.common-account .content .personal-wrap > div .my-account-phone-wrap .invalid-phone {
  margin-top: 5px;
  width: 100%;
  line-height: 1.66;
  color: #f44336;
  font: 0.75rem Roboto, sans-serif;
  font-weight: 400;
}
.account-wrap .common-wrap.common-account .content .bills-empty {
  margin-top: 30px;
  font: 25px/33px Betm Rounded Medium;
  color: #1e285a;
}
.account-wrap .common-wrap.common-account .content .divider {
  font: 14px/32px Betm Rounded Medium;
  color: #1e285a;
}
.account-wrap .common-wrap.common-account .content .bills-subscription-title {
  margin-top: 40px;
  margin-bottom: 20px;
  font: 24px/27px Betm Rounded Medium;
  color: #1e285a;
}
.account-wrap .common-wrap.common-account .content .bills-subscription-items-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  max-height: 300px;
  overflow: hidden auto;
  padding-right: 12px;
}
.account-wrap .common-wrap.common-account .content .bills-subscription-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 5px 0;
  background-color: #fff;
  border-bottom: 1px solid #1e285a;
}
.account-wrap .common-wrap.common-account .content .bills-subscription-item:hover {
  cursor: pointer;
}
.account-wrap .common-wrap.common-account .content .bills-subscription-item.sticky {
  position: sticky;
  top: 0;
}
.account-wrap .common-wrap.common-account .content .bills-subscription-item:not(:first-child) {
  border-bottom-style: dashed;
}
.account-wrap .common-wrap.common-account .content .bills-subscription-item:not(:first-child):last-child {
  border: none;
}
.account-wrap .common-wrap.common-account .content .bills-subscription-item-text {
  display: flex;
  align-items: center;
  font: 18px/32px Betm Rounded Medium;
  color: #1e285a;
}
.account-wrap .common-wrap.common-account .content .bills-subscription-item-right {
  display: flex;
  align-items: center;
  gap: 24px;
}
.account-wrap .common-wrap.common-account .content .bills-subscription-item-status {
  display: grid;
  place-items: center;
  width: 83px;
  height: 24px;
  border-radius: 4px;
  text-transform: capitalize;
  font: 18px/18px Betm Rounded Medium;
  color: #fff;
}
.account-wrap .common-wrap.common-account .content .bills-subscription-item-right.open > img {
  transform: rotate(180deg);
}
.account-wrap .common-wrap.common-account .content .bills-subscription-item-right > img {
  width: 14px;
  height: 14px;
  transition: transform 175ms ease-in-out;
  transform: rotate(0deg);
}
.account-wrap .common-wrap.common-account .content .bills-subscription-item-status.active {
  background-color: #4ab0a8;
}
.account-wrap .common-wrap.common-account .content .bills-subscription-item-status.inActive {
  background-color: #d5d5d5;
}
.account-wrap .common-wrap.common-account .content .bills-wrap {
  margin-top: 62px;
}
.account-wrap .common-wrap.common-account .content .bills-wrap li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 17px 20px 0;
  border-bottom: 1px solid #cfcfcf;
}
.account-wrap .common-wrap.common-account .content .bills-wrap li h6 {
  font: 20px/23px Betm Rounded Medium;
  color: #1e285a;
}
.account-wrap .common-wrap.common-account .content .bills-wrap li > div {
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.account-wrap .common-wrap.common-account .content .bills-wrap li > div span {
  font: 20px/23px Betm Rounded;
  font-weight: lighter;
  color: #1e285a;
}
.account-wrap .common-wrap.common-account .content .bills-wrap li > div img {
  cursor: pointer;
}
.account-wrap .common-wrap.common-account .content .navigation-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.account-wrap .common-wrap.common-account .content .navigation-wrap .logout-button {
  display: none;
}
.account-wrap .common-wrap.common-account .content .navigation-wrap .contact-us {
  display: none;
}
.account-wrap .common-wrap.common-account .content .navigation-wrap > *.link-mobile-block {
  display: none;
}
.account-wrap .common-wrap.common-account .content .navigation-wrap > *.link-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 184px;
  height: 163px;
  box-sizing: border-box;
  border: 3px solid #e9e3d6;
  border-radius: 20px;
  margin-top: 60px;
  color: #1e285a;
  font: 24px Betm Rounded Medium;
  text-decoration: none;
  text-align: center;
  padding: 10px 0;
}
.account-wrap .common-wrap.common-account .content .navigation-wrap > *.link-block .image-wrap {
  box-shadow: 0 3px 6px #0000001a;
  border: 1px solid #ffffff;
  border-radius: 5px;
  width: 82px;
  height: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
@media (max-width: 1200px) {
  .account-wrap .side-menu {
    display: none;
  }
  .account-wrap .common-wrap.common-account {
    padding: 12px 0 0;
  }
  .account-wrap .common-wrap.common-account .content {
    margin: 0 10px;
    max-width: 100%;
    padding: 22px 10px;
    min-height: 93vh;
  }
  .account-wrap .common-wrap.common-account .content .header,
.account-wrap .common-wrap.common-account .content .divider {
    display: none;
  }
  .account-wrap .common-wrap.common-account .content .help-text {
    margin: 0;
  }
  .account-wrap .common-wrap.common-account .content .dashboard-list .dashboard-item .list-status {
    display: none;
  }
  .account-wrap .common-wrap.common-account .content .error-page a.link {
    width: fit-content;
  }
  .account-wrap .common-wrap.common-account .content .payment-inputs-wrap {
    flex-direction: column;
  }
  .account-wrap .common-wrap.common-account .content .payment-data-wrap {
    flex-direction: column;
  }
  .account-wrap .common-wrap.common-account .content .payment-data-wrap-item:first-child {
    width: 100%;
  }
  .account-wrap .common-wrap.common-account .content .navigation-wrap .logout-button {
    display: block;
    text-decoration: underline;
    cursor: pointer;
    border: none;
    color: #1e285a;
    background: none;
    font: 21px/24px Betm Rounded Medium;
    margin-top: 20px;
    outline: none;
  }
  .account-wrap .common-wrap.common-account .content .navigation-wrap .contact-us {
    width: 100%;
    margin-top: 20px;
    text-align: center;
    display: block;
    text-decoration: underline;
    border: none;
    background: none;
    font: 20px/26px Betm Rounded Medium;
    color: #1e285a;
    cursor: pointer;
  }
  .account-wrap .common-wrap.common-account .content .navigation-wrap .contact-us a,
.account-wrap .common-wrap.common-account .content .navigation-wrap .contact-us a:visited,
.account-wrap .common-wrap.common-account .content .navigation-wrap .contact-us a:active {
    color: #1e285a;
  }
  .account-wrap .common-wrap.common-account .content .navigation-wrap > *.link-block {
    display: none;
  }
  .account-wrap .common-wrap.common-account .content .navigation-wrap > *.link-mobile-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 60px;
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-top: 8px;
    padding: 0 10px;
    color: #1e285a;
    font: 20px Betm Rounded Medium;
    text-decoration: none;
  }
  .account-wrap .common-wrap.common-account .content .reset-password {
    flex-wrap: wrap;
  }
  .account-wrap .common-wrap.common-account .content .reset-password button {
    margin-top: 15px;
  }
}

.cookie-acceptance-container {
  word-break: break-word;
  position: relative;
  padding: 30px 30px 5px 30px;
  text-align: center;
  background-color: #ffffff;
  font-family: "Betm Rounded";
  font-size: 20px;
  color: #1E285A;
}
.cookie-acceptance-container .cookie-title {
  position: relative;
  z-index: 2;
  font-size: 49px;
  font-weight: 700;
  padding: 20px;
}
.cookie-acceptance-container .cookie-image {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.cookie-acceptance-container .cookie-head-swappy-image {
  position: relative;
  width: calc(100% + 60px);
  right: 30px;
}
.cookie-acceptance-container .cookie-cookies-image {
  z-index: 1;
  top: 30px;
  right: 30px;
}
.cookie-acceptance-container .cookie-swappy-wrap {
  margin-top: 40px;
  border-top: 2px solid #E9E3D6;
  position: relative;
  width: calc(100% + 60px);
  right: 30px;
}
.cookie-acceptance-container .cookie-swappy-wrap .cookie-swappy-image {
  transform: translateY(-50%);
  right: 16px;
}
.cookie-acceptance-container .cookie-text {
  line-height: 1.4;
}
.cookie-acceptance-container .cookie-buttons-wrap {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cookie-acceptance-container .cookie-buttons-wrap.cookie-buttons-wrap-processed {
  margin-top: 40px;
}
.cookie-acceptance-container .cookie-buttons-wrap .cookie-button {
  font-family: "Betm Rounded Medium";
  width: fit-content;
  max-width: 340px;
}
.cookie-acceptance-container .cookie-buttons-wrap .cookie-accept-button {
  background: linear-gradient(200deg, #F48040 0%, #EF4746 100%);
  color: #fff;
  border: none;
  text-transform: uppercase;
  font-size: 0.9em;
  border-radius: 25px;
  padding: 10px 20px;
  margin: 5px;
  text-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
}
.cookie-acceptance-container .cookie-buttons-wrap .cookie-accept-button:hover {
  cursor: pointer;
  outline: none;
  border: none;
  text-shadow: none;
  box-shadow: none;
}
.cookie-acceptance-container .cookie-buttons-wrap .cookie-accept-button:focus {
  outline: none;
  border: none;
  text-shadow: none;
  box-shadow: none;
}
.cookie-acceptance-container .cookie-buttons-wrap .cookie-parameterize-button {
  font-size: 18px;
  margin-top: 10px;
  background-color: transparent;
  border: none;
  outline: none;
  color: #1E285A;
}
.cookie-acceptance-container .cookie-buttons-wrap .cookie-parameterize-button:hover {
  text-decoration: underline;
  border: none;
  outline: none;
  cursor: pointer;
}
.cookie-acceptance-container .cookie-buttons-wrap .cookie-parameterize-button:focus {
  border: none;
  outline: none;
}
.cookie-acceptance-container .cookie-processed-content-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 40px;
}
.cookie-acceptance-container .cookie-processed-content-wrap .input-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.cookie-acceptance-container .cookie-processed-content-wrap .input-checkbox:checked + label::before {
  border-color: #1E285A;
  background-color: #1E285A;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.cookie-acceptance-container .cookie-processed-content-wrap .input-checkbox:disabled + label::before {
  border-color: #1E285A;
  background-color: #1E285A;
  opacity: 0.5;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.cookie-acceptance-container .cookie-processed-content-wrap .cookie-label {
  z-index: 2;
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  user-select: none;
  margin-bottom: 20px;
  cursor: pointer;
}
.cookie-acceptance-container .cookie-processed-content-wrap .cookie-label .cookie-label-title {
  margin-bottom: 10px;
}
.cookie-acceptance-container .cookie-processed-content-wrap .cookie-label::before {
  position: absolute;
  top: 0;
  left: -30px;
  content: "";
  display: inline-block;
  width: 0.9em;
  height: 0.9em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px solid #1E285A;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.cookie-acceptance-container .cookie-processed-content-wrap .cookie-functional .cookie-label {
  cursor: initial;
}

@media (max-width: 768px) {
  .cookie-acceptance-container {
    padding: 5px;
  }
  .cookie-acceptance-container .cookie-title {
    font-size: 26px;
    padding: 15px;
  }
  .cookie-acceptance-container .cookie-head-swappy-image {
    width: calc(100% + 10px);
    right: 5px;
  }
  .cookie-acceptance-container .cookie-swappy-wrap {
    width: calc(100% + 10px);
    right: 5px;
  }
  .cookie-acceptance-container .cookie-text {
    padding: 5px;
    font-size: 17px;
  }
  .cookie-acceptance-container .cookie-buttons-wrap {
    padding: 5px;
  }
  .cookie-acceptance-container .cookie-buttons-wrap .cookie-accept-button {
    font-size: 0.7em;
  }
  .cookie-acceptance-container .cookie-processed-content-wrap {
    font-size: 16px;
  }
  .cookie-acceptance-container .cookie-processed-content-wrap .cookie-label::before {
    left: -24px;
  }
}
@media (max-width: 575px) {
  .cookie-acceptance-container .cookie-cookies-image {
    display: none;
  }
  .cookie-acceptance-container .cookie-swappy-wrap {
    display: none;
  }
  .cookie-acceptance-container .cookie-buttons-wrap.cookie-buttons-wrap-processed {
    margin-top: 0;
  }
}
