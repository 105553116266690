.selectButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #7A7A7A;
    background-color: #E9E3D666;
    border: 2px solid #E9E3D6;
    border-radius: 10px;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
    word-break: break-word;
    font-family: "Betm Rounded Medium", sans-serif;
}

.selectButton.selected {
    background-color: #E9E3D600;
    color: #1E285A;
    border: 3px solid #F48040;
}

.selectButton:disabled {
    cursor: initial;
}